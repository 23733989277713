<template>
  <div>
    <b-card>
      <div class="card-toolbar">
        <b-row>
          <b-col>
            <button
              type="reset"
              class="btn btn-success mr-2"
              @click="create()"
              ref="kt_save_changes"
              v-if="!editThreat && !showPresets"
            >
              Add New Risk
            </button>
            <button  v-if="!editThreat && !showPresets && currentThreatsData.length > 0"
              type="reset"
              class="btn btn-success mr-2"
              @click="addPresetThreats()"
              ref="kt_save_changes"

            >
              Add From Preset List
            </button>            
          </b-col>
          <b-col cols="3" v-if="!editThreat && !showPresets">
              <b-form-select
                ref="system"
                v-model="selectedSystem"
                :options="getSystemData"
              ></b-form-select>
          </b-col>
          <b-col cols="3" v-if="!editThreat && !showPresets">
              <b-form-select
                ref="framework"
                v-model="selectedFramework"
                :options="frameworkItems"
              ></b-form-select>
          </b-col>            
          <b-col cols="4" v-if="!editThreat && !showPresets">
            <b-button-group style="float:right">
              <b-button :variant="[filterBy==-1? 'primary' : 'outline-secondary']" size="sm" v-on:click="setTabClick(-1)">Show All</b-button>
              <b-button :variant="[filterBy==0? 'primary' : 'outline-secondary']" size="sm" v-on:click="setTabClick(0)">Not Set</b-button>
              <b-button :variant="[filterBy==1? 'primary' : 'outline-secondary']" size="sm" v-on:click="setTabClick(1)">Low</b-button>
              <b-button :variant="[filterBy==2? 'primary' : 'outline-secondary']" size="sm" v-on:click="setTabClick(2)">Medium</b-button>
              <b-button :variant="[filterBy==3? 'primary' : 'outline-secondary']" size="sm" v-on:click="setTabClick(3)">High</b-button>
              <b-button :variant="[filterBy==4? 'primary' : 'outline-secondary']" size="sm" v-on:click="setTabClick(4)">Very High</b-button>
            </b-button-group>            
          </b-col>
        </b-row>        
      </div>
      <div v-if="!editThreat && !showPresets">
        <b-table
          striped
          hover
          sort-icon-left          
          :items="getThreaddata"
          :fields="threatFields"
          @row-clicked="rowClickHandler"
        >
          <template #cell(actions)="data">
            <v-icon small v-on:click="deleteRecord(data.item.id)"
              >mdi-delete</v-icon
            >
            <b-button :variant="outline-secondary" size="sm" @click="duplicateRecord(data.item.id)">
              Duplicate
            </b-button>
          </template>
          <template #cell(reference)="data">
            {{data.item.reference}} <br/> <small>(v:{{data.item.updated_at.substring(0, 10)}})</small>
          </template>
          <!--
          <template #cell(is_confidentiality)="data">
            {{getCIA(data.item)}}
          </template>
          -->
          <template #cell(impact)="data">
            {{getImpact(data.item.impact)}}
          </template>
          <template #cell(probability)="data">
            {{getLikelehood(data.item.probability)}}
          </template>
          <template #cell(risk)="data">
            <div class="transparentrisk" :style="riskclass(data.item.impact,data.item.probability)">{{data.item.risk}}</div>
          </template>
          <template #cell(systems)="data">
            {{showsystems(data.item.systems)}}
          </template>
          <template #cell(frameworks)="data">
            {{showframeworks(data.item.frameworks)}}
          </template>          
        </b-table>
      </div>

      <div v-if="!editThreat && !showPresets && getThreaddata.length == 0">
            <button
              type="reset"
              class="btn btn-success mr-2"
              @click="addPresetThreats()"
              ref="kt_save_changes"
              v-if="!editThreat && !showPresets"
            >
              Click here to add threats from the Preset List
            </button>

     </div>

      <div v-if="editThreat && !showPresets">
        <KTThreatInformation
          :record="editThreatRecord"
          @canceled="finished()"
          @finished="finished()"
        />
      </div>
      <div v-if="!editThreat && showPresets">
        <ShowPresetsInformation
          :threats="getThreaddata"
          :presets="getPresetdata"
          @canceled="finishedPresets()"
          @finished="finishedPresets()"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_THREATS,
  DELETE_THREAT_DETAILS,
  DUPLICATE_THREAT_DETAILS
} from "@/core/services/store/threats.module";
import { GET_SYSTEMS } from "@/core/services/store/systems.module";
import { GET_USERS } from "@/core/services/store/users.module";
import { GET_FRAMEWORK } from "@/core/services/store/framework.module";

import KTThreatInformation from "@/view/pages/work/threats/ThreatsInformation";
import ShowPresetsInformation from "@/view/pages/work/threats/ShowPresetsInformation";

import RiskCalcs from "@/core/services/helpers/risk.calcs";

export default {
  name: "managethreats",
  components: {
    KTThreatInformation,
    ShowPresetsInformation
  },
  data() {
    return {
      editThreat: false,
      showPresets: false,
      editThreatRecord: [],
      filterBy: -1,
      selectedFramework:'',
      selectedSystem: '',

      threatFields: [
        { key: "reference" , sortable: true},
        { key: "name", label: "Threat", sortable: true },
        /*{ key: "is_confidentiality", label: "CIA", sortable: false },*/
        { key: "probability", sortable: true  },
        { key: "impact" , sortable: true },
        { key: "risk" , sortable: true },
        { key: "systems", label: "Assets / Systems" , sortable: true },
        { key: "frameworks", label: "Controls" , sortable: true },
        { key: "actions", label: "" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Risk Register" }]);
    this.getData(true);
  },
  methods: {
    getData(checkID = false) {
      this.$store.dispatch(GET_THREATS);
      this.$store.dispatch(GET_USERS);    
      this.$store.dispatch(GET_SYSTEMS);  
      var frameworkID = '';
      if (this.currentFramework.framework) {
        frameworkID = this.currentFramework.framework.id;
      }

      this.$store.dispatch(GET_FRAMEWORK,frameworkID);        


      if (checkID) {

        console.log(this.$route.params)
     
        if (this.$route.params.framework) {
          this.selectedFramework = this.$route.params.framework;
        }

        if (this.$route.params.system) {
          this.selectedSystem = this.$route.params.system;
        }  
       
      }


    },
    setTabClick(type) {
      this.filterBy = type
    },    
    riskclass(impact,probability) {
      console.log(this.currentUser);
        return RiskCalcs.getRiskColor(impact,probability,this.currentUser.riskreview)
    },        
    showsystems(values) {
      var retVal = '';
      if (values) {
        values.forEach(value => {
          if (retVal == '' ) { retVal = value.name } else { retVal = retVal + ', ' + value.name}
          
        });
      }
      if (retVal == '') { retVal = '-'}
      return retVal;
    },
    showframeworks(values) {
      var retVal = '';
      if (values) {
        values.forEach(value => {
          if (retVal == '' ) { retVal = value.name } else { retVal = retVal + ', ' + value.name}
          
        });
      }
      if (retVal == '') { retVal = '-'}
      return retVal;
    },

    getCIA(values) {
        var result = '';
        console.log('cis',values);
        if (values.is_confidentiality) { result = result + 'C' }
        if (values.is_integrity) { result = result + 'I' }
        if (values.is_accessibility) { result = result + 'A' }
        return result;
    },       
    getLikelehood(value) {
        var result = 'Not Set';
        if (value == 1) { result = 'Rare' }
        if (value == 2) { result = 'Unlikely' }
        if (value == 3) { result = 'Possible' }
        if (value == 4) { result = 'Likely' }
        if (value == 5) { result = 'Certainly' }
        return result;
    },        
    getImpact(value) {
        var result = 'Not Set';
        if (value == 1) { result = 'Negligible' }
        if (value == 2) { result = 'Low' }
        if (value == 3) { result = 'Medium' }
        if (value == 4) { result = 'High' }
        if (value == 5) { result = 'Critical' }
        return result;
    },          
    finished() {
      this.editThreat = false;
      this.editThreatRecord = [];
      this.$router.push('/threats/');
        setTimeout(() => {
          this.getData();
        }, 500);
    },
    finishedPresets() {
      this.showPresets = false;
        setTimeout(() => {
          this.getData();
        }, 500);
    },    
    rowClickHandler(record) {
      this.$router.push('/threats/' + record.id);
      this.editThreat = true;
      this.editThreatRecord = record;
    },

    create() {
      this.editThreat = true;
      var threatLength =  String('00000' + this.getThreaddata.length).slice(-4);

      this.editThreatRecord = {
        id: -1,
        reference: "TR:" + threatLength,
        name: "",
        description: "",
        probability: 0,
        impact: 0,
        risk: 0,
      };
    },
    addPresetThreats() {
      this.showPresets = true;
    },
    deleteRecord(id) {
      console.log("delete");
      if (confirm("Do you really want to delete?")) {
        this.editThreat = false;
        this.$store.dispatch(DELETE_THREAT_DETAILS, {
          id,
        });
        setTimeout(() => {
          this.getData();
        }, 500);
      }
    },
    duplicateRecord(id) {
      console.log("suplicate");
      this.$store.dispatch(DUPLICATE_THREAT_DETAILS, {
        id,
      });
      setTimeout(() => {
        this.getData();
      }, 500);
    },    
  },
  computed: {
    ...mapGetters(["currentThreatsData","currentThreatsPresetData","currentFramework","currentSystemsData","currentUser"]),
    getName() {
      var result = "";
      if (this.currentFramework.framework) {
        result = this.currentFramework.framework.name;
      }
      return result;
    },
    getThreaddata() {

      console.log('this.currentThreatsData',this.currentThreatsData);

      if (this.currentThreatsData) {
        if (this.currentThreatsData.length == 0) {        
          return [];
        }
      }

      if (this.filterBy == -1) {
        if (this.currentThreatsData) {
          return this.currentThreatsData.filter((item) => {
            return (JSON.stringify(item.systems).includes(this.selectedSystem) && JSON.stringify(item.frameworks).includes(this.selectedFramework))
          })

        }
      }
      if (this.filterBy == 0) {
        return this.currentThreatsData.filter((item) => {
          return (JSON.stringify(item.systems).includes(this.selectedSystem) && JSON.stringify(item.frameworks).includes(this.selectedFramework) && item.risk == 0)
        })
      }
      if (this.filterBy == 1) {
        return this.currentThreatsData.filter((item) => {
          return (JSON.stringify(item.systems).includes(this.selectedSystem) && JSON.stringify(item.frameworks).includes(this.selectedFramework) && item.risk > 0 && item.risk <= 3 )
        })
      }
      if (this.filterBy == 2) {
        return this.currentThreatsData.filter((item) => {
          return (JSON.stringify(item.systems).includes(this.selectedSystem) && JSON.stringify(item.frameworks).includes(this.selectedFramework) && item.risk >= 4 && item.risk <= 7 )
        })
      }
      if (this.filterBy == 3) {
        return this.currentThreatsData.filter((item) => {
          return (JSON.stringify(item.systems).includes(this.selectedSystem) && JSON.stringify(item.frameworks).includes(this.selectedFramework) && item.risk >= 8 && item.risk <= 11 )
        })
      }
      if (this.filterBy == 4) {
        return this.currentThreatsData.filter((item) => {
          return (JSON.stringify(item.systems).includes(this.selectedSystem) && JSON.stringify(item.frameworks).includes(this.selectedFramework) && item.risk >= 12 )
        })
      }
      return [];
    },
    getPresetdata() {
      return this.currentThreatsPresetData;
    },    
    frameworkItems() {
      var results = [];
      results.push({
        value: '',
        text: 'All Framework controls',
      });      
     
      if (this.currentFramework.framework_items) {   
        this.currentFramework.framework_items.forEach((item) => {

          results.push({
            value: item.id,
            text: item.objective_code + ' - ' + item.objective,
          });
        });
      }
      return results;
    }, 
    getSystemData() {
      var systems = [];
      systems.push({
        value: '',
        text: 'All systems',
      });            
      if (this.currentSystemsData) {
        if (this.currentSystemsData.length > 0) {

          this.currentSystemsData.forEach(system => {
            systems.push({value:system.id,text: (system.name + "(" + system.category + ")") })
            
          });
        }
      }
      return systems
    },    
  },
  watch: {
    currentFrameworkStats: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      this.showData = false;
      this.frameworkStats = this.getFrameworkStatsData(this.currentFrameworkStats.frameworkStats);
      console.log("frameworkStats",this.frameworkStats);
      this.showData = true;
    }, 
    currentThreatsData: function() {

        console.log('theats',this.getThreaddata);

        if (this.$route.params.id) {
          
          this.getThreaddata.forEach((threatItem) => {
            if (this.$route.params.id == threatItem.id) {
              this.editThreatRecord = threatItem;
              this.editThreat = true;
            }
          })
          
          
        }         
    },
    '$route' () {
      if (!this.$route.params.id) {this.editThreat = false;} else {
          
          this.getThreaddata.forEach((threatItem) => {
            if (this.$route.params.id == threatItem.id) {
              this.editThreatRecord = threatItem;
              this.editThreat = true;
            }
          })        
      }
    }
  },    
};
</script>

<style>


  .table-hover tr td {
      cursor: pointer;
  }
  
</style>
